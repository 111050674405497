import { CLOUDINARY_FETCH_IMAGE_URL, CLOUDINARY_DEFAULT_OPTIONS, CLOUDINARY_YOUTUBE_THUMBNAIL_URL, DEFAULT_ELP_SORTER_VALUE, ELPSorters, PLAY_ICON, PAYMENT_URL, CLOUDINARY_FETCH_IMAGE_URL_OLD } from "../constants";
import { slugify } from "../../helpers/misc";
import queryString from "query-string";
import { getSlugFromEDPHref, isEDPHref } from "../misc";
import { isEmpty } from "lodash";
/**
 * Creates an image options string
 * in the format Cloudinary wants
 * eg - `w_50,f_auto`
 * @param {object} opts
 * @return {string}
 */
const createImageOptionString = opts => {
    // fill in default options
    const options = Object.assign(Object.assign({}, CLOUDINARY_DEFAULT_OPTIONS), opts);
    delete options.q; // remove quality option
    return Object.keys(options)
        .filter(k => options[k]) // remove undefined entries
        .map(k => `${k}_${options[k]}`)
        .join(",");
};
/**
 * Generates cloudinary specific image options
 * @param {string} src
 * @param {object} [opts]
 */
const generateImageOptions = ({ src, opts }) => `${createImageOptionString(opts)}/${encodeURIComponent(src)}`;
/**
 * Generates a Cloudinary cached image link
 * @param {string} src
 * @param {object} [opts]
 * @return {string}
 */
export const imageCacheLink = (src, opts) => {
    const isMediaLink = src ? src.includes("https://media.insider.in/image/upload/") : false;
    if (isMediaLink) {
        // remove `https://media.insider.in/image/upload/` from image links
        let newString = src ? src.replace("https://media.insider.in/image/upload/", "") : src;
        const imageTransformOptions = generateImageOptions({ src: newString, opts });
        return `${CLOUDINARY_FETCH_IMAGE_URL}/${imageTransformOptions}`;
    }
    else {
        //for backward compatibility, let old links be treated as before
        const imageTransformOptions = generateImageOptions({ src, opts });
        return `${CLOUDINARY_FETCH_IMAGE_URL_OLD}/${imageTransformOptions}`;
    }
};
/**
 * Generates a Cloudinary cached image link with chained transforms
 * @param {string} src
 * @param {object} [opts]
 * @return {string}
 */
export const imageCacheLinkWithChainedTransforms = (src, optsList) => {
    const transforms = optsList.map(opts => createImageOptionString(opts)).join("/");
    const isMediaLink = src ? src.includes("https://media.insider.in/image/upload/") : false;
    if (isMediaLink) {
        // remove `https://media.insider.in/image/upload/` from image links
        let newString = src ? src.replace("https://media.insider.in/image/upload/", "") : src;
        return `${CLOUDINARY_FETCH_IMAGE_URL}/${transforms}/${encodeURIComponent(newString)}`;
    }
    else {
        `${CLOUDINARY_FETCH_IMAGE_URL_OLD}/${transforms}/${encodeURIComponent(src)}`;
    }
};
/**
 * Generates a Cloudinary cached image link for faces with the face off center
 * @param {string} src
 * @param {number} w
 * @param {number} h
 * @param {number} hOffset
 * @return {string}
 */
export const imageFaceTransform = (src, w, h, hOffset) => {
    // we use a chained transform so that the face can be a little off center
    const imageOptions = { c: "fill", h: h, g: "face" };
    const imageOptions2 = { c: "thumb", h: h, g: "west" };
    return imageCacheLinkWithChainedTransforms(src, [
        Object.assign({ w: w + hOffset }, imageOptions),
        Object.assign({ w: w }, imageOptions2)
    ]);
};
/**
 * Creates a Cloudinary link to convert a gif to a mp4 video
 * @param {string} src
 * @return {string}
 */
export const gifVideoLink = src => `${CLOUDINARY_FETCH_IMAGE_URL_OLD}/vc_auto,f_mp4/${src}`;
/**
 * Generates a srcset with cached Filestack links
 * @param {string} src
 * @param {number[]} [widths]
 * @return {string}
 */
export const imageCacheLinkSet = (src, widths = []) => widths.reduce((link, value) => `${link}${imageCacheLink(src, { w: value })} ${value}w, `, "");
// URL helpers
/**
 * Generates the go out link
 * NOTE : If no city is selected, then city = ''
 * @param {string} city
 * @param {boolean} isSelectedCityInferred
 * @return {string}
 */
export const generateGooutLink = city => {
    if (city) {
        return `/${city}`;
    }
    return "/";
};
/**
 * Generate All Venues Link
 * @returns {string}
 */
export const generateAllVenuesLink = city => `/venues-in-${city}`;
/**
 * Generate All Artists Link
 * @returns {string}
 */
export const generateAllArtistsLink = () => `/artists`;
/**
 * Generate Partner Profile Link
 */
export const generatePartnerProfileLink = (slug, model) => `/${encodeURIComponent(slug)}/${model}`;
export const addSortUrlParam = (url, sort) => (sort && sort !== DEFAULT_ELP_SORTER_VALUE ? `${url}?sort=${sort}` : url);
export const addPriceFilterParam = (url, priceFilter) => {
    const isNewParam = url.includes("?");
    return priceFilter ? `${url}${isNewParam ? "&" : "?"}priceFilter=${priceFilter}` : url;
};
const generateURLWithParams = (url, sort, priceFilter, maxPrice) => {
    let params = {};
    if (sort && sort !== DEFAULT_ELP_SORTER_VALUE) {
        params = { sort };
    }
    if (priceFilter) {
        params = Object.assign(Object.assign({}, params), { priceFilter });
    }
    if (maxPrice) {
        params = Object.assign(Object.assign({}, params), { maxPrice });
    }
    if (!isEmpty(params)) {
        return url + "?" + new URLSearchParams(params).toString();
    }
    return url;
};
/**
 * Go Out SEO friendly URL routes for CATEGORIES eg: /events-in-mumbai and /theatre-in-kolkata
 * @param city
 * @param group
 * @param {string=} timeFilter
 * @param {string=} sort
 * @param {string=} priceFilter
 * @param {string=} type
 * @returns {string}
 */
export const generateSEOCategoryLink = (city, label, timeFilter, sort, priceFilter, type) => {
    let result = `/${slugify(label)}-in-${city}`;
    if (timeFilter) {
        result = `${result}-${timeFilter}`;
    }
    result = addSortUrlParam(result, sort);
    return addPriceFilterParam(result, priceFilter);
};
/**
 * Go Out SEO friendly URL routes for EVERYTHING pages eg: /everything-in-mumbai and /everything-in-kolkata-today
 * @param city
 * @param time
 * @returns {string}
 */
export const generateSEOEverythingLink = (selectedCity, time, sort, type) => {
    let result = `/${selectedCity}`;
    if (time) {
        result = `/everything-in-${selectedCity}-${time}`;
    }
    return addSortUrlParam(result, sort);
};
/**
 * Go Out SEO friendly URL routes for GROUPS eg: /all-events-in-mumbai and /all-travel-in-mumbai
 * @param city
 * @param label
 * @returns {string}
 */
export const generateSEOGroupLink = (city, label, timeFilter, sort, priceFilter, type, maxPrice) => {
    const labelSanitized = label.replace(/\s/g, "-");
    let result = `/all-${encodeURIComponent(labelSanitized.toLowerCase())}-in-${city}`;
    if (timeFilter) {
        result = `${result}-${timeFilter}`;
    }
    return generateURLWithParams(result, sort, priceFilter, maxPrice);
};
/**
 * Generate URL routes for All events
 * eg: /all-events-in-mumbai
 * @param city
 * @returns {string}
 */
export const generateLinkForAllEvents = city => `/all-events-in-${city}`;
/**
 * Go Out SEO friendly URL routes for GROUPS and CATEGORIES eg: /all-music-events-in-mumbai and /all-camping-travel-in-mumbai
 * @param city
 * @param label
 * @returns {string}
 */
export const generateSEOGroupCategoryLink = (city, category, group, timeFilter, sort, priceFilter, type, maxPrice) => {
    let result = `/all-${slugify(group)}-in-${city}`;
    if (timeFilter) {
        result = `${result}-${timeFilter}`;
    }
    if (category) {
        result = `${result}/${slugify(category)}`;
    }
    return generateURLWithParams(result, sort, priceFilter, maxPrice);
};
/**
 * The standalone Search page link
 * @param {string} [query]
 * @return {string}
 */
export const generateSearchLink = query => {
    if (query) {
        return `/search?q=${encodeURIComponent(query)}`;
    }
    return "/search";
};
/**
 * Generates slug,type from link
 * @param {string} link
 * @return {object}
 */
export const generateSlugTypeFromLink = link => {
    if (link.endsWith("/event")) {
        const slug = link
            .split("/event")[0]
            .split("/")
            .pop();
        return {
            type: "event",
            slug
        };
    }
    if (link.endsWith("/article")) {
        const slug = link
            .split("/article")[0]
            .split("/")
            .pop();
        return {
            type: "article",
            slug
        };
    }
    if (link.endsWith("/artist")) {
        const slug = link
            .split("/artist")[0]
            .split("/")
            .pop();
        return {
            type: "artist",
            slug
        };
    }
    if (link.endsWith("/venue")) {
        const slug = link
            .split("/venue")[0]
            .split("/")
            .pop();
        return {
            type: "venue",
            slug
        };
    }
    return {};
};
/**
 * Generates the link to Event details page
 * NOTE : This also optionally loads the URI scheme
 * when loaded from the app
 * @param {string} slug
 * @param {boolean} [isFromApp]
 * @return {string}
 */
export const generateEventLink = (slug, isFromApp = false) => {
    // if (isFromApp) {
    // 	return `insiderconsumer://eventDetail/${slug}`;
    // }
    return `/${slug}/event`;
};
// Generates the link to Event details page for apps if href is of EDP
export const generateEventLinkFromHref = (href, isFromApp = false) => {
    // if (isEDPHref(href) && isFromApp) {
    // 	return `insiderconsumer://eventDetail/${getSlugFromEDPHref(href)}`;
    // }
    return href;
};
/**
 * Generates the direct link to the buy page
 * @todo  Add a URI scheme option here
 * @param {string} slug
 * @return {string}
 */
export const generateEventBuyLink = (slug, platform, shouldShowReactBuyPage = true, disableSocialLogin = false) => {
    let buyLink = `/event/${slug}/buy`;
    if (shouldShowReactBuyPage) {
        buyLink = `/event/${slug}/buy-page`;
    }
    if (disableSocialLogin) {
        buyLink = `${buyLink}?disableSocialLogin=true`;
    }
    const isFirstParam = !buyLink.includes("?");
    if (platform === "android" || platform === "ios" || platform === "samsungwallet") {
        buyLink = `${buyLink}${isFirstParam ? `?` : `&`}platform=${platform}`;
    }
    return buyLink;
};
/**
 * Generates link to scroll to Venue
 * @todo  Add a URI scheme option here
 * @param {string} slug
 * @return {string}
 */
export const generateEventTicketAtVenueLink = slug => `/${slug}/event#section-venue`;
/**
 * Generates the buy together link for the event
 * @todo  Add a URI scheme option here
 * @param {string} slug
 * @return {string}
 */
export const generateEventBuyTogetherLink = slug => `/event/${slug}/buy-together`;
/**
 * For a legacy cell, we either load the older
 * Tag template pages - `/go`, or we go the
 * absolute link specified for the cell
 * @param {string} [slug]
 * @param {string} [link]
 * @return {string}
 */
export const generateCellLink = ({ slug, link }) => {
    if (slug) {
        return `/go/${slug}`;
    }
    return link;
};
/**
 * Generates the link to an article page
 * @param {string} slug
 * @return {string}
 */
export const generateArticleLink = slug => `/${slug}/article`;
/**
 * Helper function to generate an absolute URL
 * @param {string} path
 * @return {string}
 */
export const generateAbsoluteUrl = path => `https://insider.in${path}`;
/**
 * This generates a direct link to the location
 * on Google Maps
 * @param {number} latitude
 * @param {number} longitude
 * @return {string}
 */
export const generateGoogleMapsLink = ({ latitude, longitude }) => `https://maps.google.com/?q=${latitude},${longitude}`;
/**
 * Extracts the Youtube Video ID from a URL
 * NOTE : This only works for embed URLs right now
 * eg - https://www.youtube.com/embed/I5Uv6cb9YRs?modestbranding=1
 * @param {string} link
 * @return {string}
 */
export const extractYoutubeIdFromUrl = link => {
    const splitLink = link.split("/");
    // It'll always be the last one
    const embedSrc = splitLink[splitLink.length - 1];
    // However, this can have query params as well
    return embedSrc.split("?")[0];
};
/**
 * For a give Youtube video, we use this to generate a thumbnail
 * image. Refer https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
 * @param {string} youtubeLink
 * @return {string}
 */
export const generateYoutubePosterLink = youtubeLink => `${CLOUDINARY_YOUTUBE_THUMBNAIL_URL}/${createImageOptionString({
    // the options for the YT thumbnail
    f: "auto",
    fl: "progressive",
    w: 1645
})}/${createImageOptionString({
    // add a play button
    l: PLAY_ICON,
    w: 180
})}/${extractYoutubeIdFromUrl(youtubeLink)}`;
/**
 * Simple helper to create a URL param string
 * from an Object
 * @param {object} queryOpts
 * @return {string}
 */
export const constructUrlParamString = queryOpts => {
    let queryParams = [];
    for (let k in queryOpts) {
        if (queryOpts.hasOwnProperty(k)) {
            queryParams.push(`${k}=${queryOpts[k]}`);
        }
    }
    return queryParams.join("&");
};
export const generateSummerGroupLink = (selectedCity, group) => {
    return `summer-${group}-in-${selectedCity}`;
};
export const generateItemUrl = (eventSlug, showId, itemGroupName) => {
    return `/event/${eventSlug}/buy/shows/${showId}/${itemGroupName}`;
};
export const generateLogoutLink = persistCart => {
    return "/users/logout" + (persistCart ? "?persistCart=true" : "");
};
export const generateReprocessLink = () => "/cart/reprocess";
export const generateSignupLink = () => "/users/register";
export const generateLoginWithPaytmLink = () => "/users/loginWithPaytm";
export const generateRequestPasswordResetLink = () => "/users/requestPasswordReset";
export const generatePasswordResetLink = () => "/users/resetPassword";
export const generatePaymentOptionsLink = (transactionId, apiKey, userId) => `/cart/payment-options?transactionId=${transactionId}&apiKey=${apiKey}`;
export const generateStripeRedirectLink = () => "/cart/payment/stripe";
export const generateCheckoutCartLink = () => "/cart/checkout";
export const generateResetCartTimerLink = () => "/cart/resetTimer";
export const generatePaymentSuccessLink = (transactionId, platform) => {
    if (platform === "ios" || platform === "android" || platform === "samsungwallet")
        return `/payments/success?transactionId=${transactionId}&platform=${platform}`;
    return `/payments/success?transactionId=${transactionId}`;
};
export const generateUpdateCartLink = () => "/cart/update";
export const generateEnableCancellationProtectLink = () => "/cart/cancellationProtect/enable";
export const generateDisableCancellationProtectLink = () => "/cart/cancellationProtect/disable";
export const generateApplyCouponLink = coupon => `/cart/applyDiscountCoupon/${coupon}`;
export const generateUpdateItemsInCartLink = () => "/cart/updateItemsInCart";
export const generateRemoveCouponLink = () => "/cart/removeDiscountCoupon";
export const generateUpdateDeliveryDetailsLink = () => "/cart/updateDeliveryDetails";
// generate all the urls for the profile page
export const generateUserProfileBaseLink = () => "/users/me";
export const generateUserProfileTicketsLink = () => "/users/me/tickets";
export const generateUserProfilePassesLink = () => "/users/me/passes";
export const generateUserProfileFavouritesLink = () => "/users/me/favourites";
export const generateUserProfileLink = () => "/users/me/profile";
export const generateUserProfileStashesLink = () => "/users/me/wallet";
export const generateAddMoneyUri = (amount, apiKey, transactionId, source) => `/payments/paytm/addMoney?amount=${amount}&apiKey=${apiKey}&transactionId=${transactionId}&source=${source}`;
export const generateIntentUri = () => {
    return `/payments/processIntent`;
};
export const generateCitySelectorLink = () => `/city-selector`;
export const generatePaymentCompleteLink = (transactionId, pg_type) => `${PAYMENT_URL}/completeTransaction/${pg_type}?transaction_id=${transactionId}`;
export const generatePaymentFailureLink = (transactionId, apiKey) => {
    return `/payments/failure?transactionId=${transactionId}&apiKey=${apiKey}`;
};
export const appendPlatformToLink = path => {
    const isFirstParam = !path.includes("?");
    let platform;
    if (window.Insider) {
        platform = "android";
    }
    else if (window.webkit) {
        platform = "ios";
    }
    return `${path}${isFirstParam ? "?" : "&"}platform=${platform}`;
};
export const generateCheckoutPageLink = () => "/buy/checkout";
// modifyParam is a key-value pair
export const generateQueryString = (pathname, queryParams, modifyParams) => {
    const modifiedQueryParams = queryString.stringify(Object.assign(Object.assign({}, queryParams), modifyParams));
    if (modifiedQueryParams) {
        return `${pathname}?${modifiedQueryParams}`;
    }
    return pathname;
};
export const getPaymentsSuccessUri = txnId => `/payments/success?transactionId=${txnId}`;
export const generatePassWebflowLink = eventId => {
    return `/unlocked-pass?event_id=${eventId}`;
};
export const getItemUrl = (item, city) => {
    switch (item.get("resource_type")) {
        case "tag":
            return `/online-events/${item.get("slug")}`;
        case "event":
        case "article":
        case "artist":
        case "venue":
            return `/${item.get("slug")}/${item.get("resource_type")}`;
        case "category":
            return generateSEOCategoryLink(city, item.get("title"), item.get("timeFilter"));
        case "external":
            return item.get("url");
        case "group":
            return generateSEOGroupLink(city, item.get("title"));
        case "go":
            return `/go/${item.get("slug")}`;
        case "filtered_list":
            let queryParams = {};
            const keys = ["maxPrice", "sort", "type"]; // keys allowed in query_params obj
            keys.map(key => {
                if (item.getIn(["query_params", key])) {
                    queryParams[key] = item.getIn(["query_params", key]);
                }
            });
            const url = generateSEOCategoryLink(city, item.get("title"));
            return `${url}${url.includes("?") ? "&" : "?"}${new URLSearchParams(queryParams).toString()}`;
        default:
            return "/";
    }
};
//replacing current city name in URL  with selected city name
//in city change /new city selection
export const generateSamePageURL = (currentCity, newCity) => {
    if (currentCity && newCity) {
        const regex = new RegExp(`\\b${currentCity.toLowerCase()}\\b`, "gi");
        const currentURL = window.location && window.location.pathname;
        const updatedUrl = currentURL.replace(regex, newCity.toLowerCase());
        //  for H5, "/online" coming as "/"
        if (currentURL === "/" && currentCity.toLowerCase() !== newCity.toLowerCase()) {
            return `/${newCity.toLowerCase()}`;
        }
        return updatedUrl;
    }
    return "/";
};
